import { merge } from 'lodash'
import themeSettings from '~/themes/base/settings'

const overloadSettings = {
  catalog: {
    defaultSortType: 'MostSoldSort',
  },
  checkout: {
    methodsInputType: 'radio',
  },
  selectBoxIcon: 'arrow-down-bold',
  sitesUrl: {
    entry: {
      cs: 'https://www.nobilis.cz',
      sk: 'https://www.nobilis.cz',
    },
  },
  blocks: {
    LandingContentListBlock: {
      numberList: false,
    },
  },
  components: {
    AddToCartModalItemAdded: {
      buttonCheckoutIcon: '',
      buttonContinueShoppingIcon: '',
    },
    BranchesMap: {
      filter: {
        enable: true,
        fullViewportWidthWrapper: true,
      },
      map: {
        fullViewportWidth: true,
      },
      markers: {
        displayOpeningHours: false,
      },
    },
    Carousel: {
      showNavigation: true,
      showNavigationOnMobile: false,
      showPagination: false,
      showPartOfNextProduct: true,
    },
    CartInfoDropDownContent: {
      buttonCheckoutIcon: '',
      showBottomLine: false,
      showCartItemAmount: true,
      showDeliveryDate: false,
    },
    CatalogFiltersWidgetSelect: {
      showLabel: true,
    },
    CatalogSorting: {
      options: ['DefaultSort', 'PriceSortASC', 'PriceSortDESC', 'NameSortASC', 'NameSortDESC'], // MostSoldSort, PriceSortASC, PriceSortDESC, NameSortASC, NameSortDESC
    },
    CookieConsent: {
      enable: false,
      position: 'bottom', // top, bottom
    },
    CurrencySwitcher: {
      displayNameType: 'code', // code, name, symbol
      dropdownTriggerType: ['hover'],
    },
    EmptyCart: {
      iconOnRight: true,
    },
    FooterBox: {
      enable: false,
    },
    HeaderMain: {
      showLanguageSwitcher: true,
    },
    LanguageSwitcher: {
      dropdown: true,
      enable: true, // Set `$languageSwitcherEnable` in `_variables.scss`
      icon: {
        active: '',
        inactive: '',
      },
      type: 'flag', // code, name, image, flag
    },
    OrderBox: {
      showItemsAmount: true,
    },
    PageAboutPurchase: {
      showAfterPurchase: false,
    },
    PageCatalog: {
      perPage: 24,
    },
    PageCheckoutInfo: {
      buttonBackIcon: '',
      buttonProceedCheckoutIcon: '',
      checkoutRecapBoxProductsMinCountToScroll: 5,
      checkoutRecapBoxScrollable: true,
      disableBusinessInputsForLoggedUser: true,
      input: {
        companyName: true,
        shippingBusinessName: {
          enable: true,
        },
        shippingPhone: true,
        vatinsvk: {
          showForNonLoggedUser: false,
          showForLoggedUser: true,
        },
      },
      layout: 'LayoutBase', // LayoutBase, LayoutCheckout
      newsletter: {
        showCheckboxForLoggedUser: true,
      },
    },
    PageCheckoutItems: {
      buttonContinueShoppingIcon: '',
      buttonCheckoutIcon: '',
      layout: 'LayoutBase', // LayoutBase, LayoutCheckout
      showPrintedMaterialsCheckbox: false,
      showProductsRecommended: false,
    },
    PageCheckoutMethods: {
      buttonBackIcon: '',
      buttonProceedCheckoutIcon: '',
      checkoutRecapBoxProductsMinCountToScroll: 5,
      checkoutRecapBoxScrollable: true,
      layout: 'LayoutBase', // LayoutBase, LayoutCheckout
    },
    PageContact: {
      showBranchesMap: true,
      showEmployees: true,
      showPrimaryBranchMap: true,
    },
    PageCourses: {
      Pagination: {
        bottom: {
          showButtonNextToPagination: false,
          showOnDesktop: true,
          showOnMobile: true,
        },
        button: {
          style: 'primary',
        },
        top: {
          showOnDesktop: true,
          showOnMobile: true,
        },
      },
      perLine: {
        md: 2,
        xl: 3,
      },
    },
    PageHomepage: {
      featuredArticles: {
        enable: false,
      },
      mostSoldProducts: {
        enable: true,
        perLine: {
          md: 2,
          lg: 3,
          xl: 4,
        },
      },
      newestProducts: {
        enable: false,
      },
    },
    PageInternalError: {
      defaultImage: false,
      showProductsRecommended: false,
    },
    PageNotFound: {
      defaultImage: false,
    },
    PageProductDetail: {
      alternativeProducts: {
        enable: true,
      },
      showProductReviews: true,
      showProductsRecommended: false,
      reviews: {
        enable: true,
        initialCount: 5,
        onlyWithReview: 0,
      },
    },
    PageTextPage: {
      sidebar: {
        enable: false,
      },
    },
    PageUserAddressList: {
      addressTypes: {
        billing: {
          enableActions: {
            delete: false,
            edit: false,
          },
        },
        other: {
          enable: false,
        },
      },
    },
    PageUserOrderDetail: {
      showTracking: true,
    },
    ProductAvailabilityWatcher: {
      button: {
        icon: '',
        size: '',
      },
      enable: true,
    },
    ProductReviewList: {
      Pagination: {
        button: {
          style: 'outlined',
        },
      },
    },
    RemoveButton: {
      icon: 'cross-circle',
    },
    RedirectModal: {
      enable: true,
      target: '_blank',
      url: {
        cs: 'https://b2b.nobilis.cz/',
        sk: 'https://b2b.nobilis-tilia.sk/',
      },
    },
    SearchInput: {
      enableDropdown: true,
      clearInputAfterSubmit: false,
      label: {
        icon: true,
      },
      button: {
        icon: false,
        show: false,
      },
    },
    StarRating: {
      activeColor: '#d49d2d',
      inactiveColor: '#dee2e6',
    },
    Sticky: {
      enable: true,
    },
    TopCategories: {
      perLine: {
        xs: 2,
        sm: 2,
        md: 3,
      },
    },
    UniqueSellingPoints: {
      enable: false,
    },
    UserAddressModalForm: {
      addressTypes: {
        billing: {
          enable: false,
        },
        other: {
          enable: false,
        },
      },
    },
  },
  layouts: {
    LayoutBase: {
      headerHeaderTopEnable: true,
      headerHeaderMainEnable: true,
      headerMainMenuEnable: true,
      headerStickyEnable: true,
    },
  },
}

export default merge(themeSettings, overloadSettings)
